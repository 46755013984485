// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  defaultauth: 'aws',

  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  confirm: {
   email: '',
   password: ''
  },
  urlapi:{
   default:'https://wowb18oqj6.execute-api.us-east-1.amazonaws.com/test/',
   recomendacion:'https://apirecom.test.reliabytics.com/',
   rondasoperacionales:'https://apironda.test.reliabytics.com/',
   rondasoperacionales_tarea:'https://apitarea.test.reliabytics.com/',

   intervencionequipos:'https://cyqzq2zi4h.execute-api.us-east-1.amazonaws.com/pocbackend/',
   intervencionequiposfuga:'https://rkymk5y6g1.execute-api.us-east-1.amazonaws.com/pocbackend/',
   jerarquiaequipos:'https://jjcm8l4rei.execute-api.us-east-1.amazonaws.com/pocbackend/',
   juntasbridadas:'https://6338x24t1i.execute-api.us-east-1.amazonaws.com/pocbackend/',
   maquetaforge:'https://api.modelo3d.reliabytics.com/api/forge/oauth/token',
   topbar:"https://w40cwl5ok0.execute-api.us-east-1.amazonaws.com/pocbackend/",
   ciclovida:"https://rf24vlrjri.execute-api.us-east-1.amazonaws.com/",
   recomendacion_aviso:"https://qwuhk7pc6e.execute-api.us-east-1.amazonaws.com/pocbackend/v1/",
   recomendacion_comp:"https://3nqefd0q0l.execute-api.us-east-1.amazonaws.com/pocbackend/",
   dataloadersrbi:"https://owmjhxvqba.execute-api.us-east-1.amazonaws.com/dev/",
   panelrbi:"https://eupranim6e.execute-api.us-east-1.amazonaws.com/pocbackend/",
   usuario_roles:'https://ft7hbykis7.execute-api.us-east-1.amazonaws.com/pocbackend/',
   alarma:"https://rkvwv2k3qa.execute-api.us-east-1.amazonaws.com/tenant/",
   quicksight:"",
   webSocket:"wss://rqbm4gaqf3.execute-api.us-east-1.amazonaws.com/Prod/",
   wssgetAlarmas:"getAlarmas",
   wssupdateAlarma:"updateAlarma"
  },
  identityPoolId:'us-east-1:d1130cea-02ef-4f0a-99e1-0ec5e789ab00',
  bucketName: 'reliabytics-test-dev'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
