/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//// Datos de Test PROD //////////////

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:d1130cea-02ef-4f0a-99e1-0ec5e789ab00",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_aXRRpQQcZ",
  "aws_user_pools_web_client_id": "2qsbn7afm2iq02j1762orughel",
  "oauth": {},
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": []

};

export default awsmobile;

